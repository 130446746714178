* {
  box-sizing: border-box;
}

code {
  font-family: Inter;
}
.fit-four-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
.fit-three-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.fit-two-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.fit-one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins/Poppins-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins/Poppins-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-display: swap; 
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins/Poppins-Italic.ttf") format("truetype");
  font-weight: 400;
  font-display: swap; 
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins/Poppins-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins/Poppins-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins/Poppins-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}

/* Inter font */

@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter/Inter-Thin.ttf") format("truetype");
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter/Inter-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap; 
}
@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-display: swap; 
}
@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter/Inter-Black.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
}

/* Manrope font */

@font-face {
  font-family: "Manrope";
  src: url("assets/fonts/Manrope/Manrope-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
}
@font-face {
  font-family: "Manrope";
  src: url("assets/fonts/Manrope/Manrope-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "Manrope";
  src: url("assets/fonts/Manrope/Manrope-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Manrope";
  src: url("assets/fonts/Manrope/Manrope-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Manrope";
  src: url("assets/fonts/Manrope/Manrope-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "Manrope";
  src: url("assets/fonts/Manrope/Manrope-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "Manrope";
  src: url("assets/fonts/Manrope/Manrope-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
}